import React from 'react'
import {Link} from 'gatsby'
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import LeftMenu from './leftMenu'
import "../../components/fontawesome.css"


const CallForEntries = () => {
  return (
    <Layout>
    <SEO title="Essay Competition Sponsored By The Mccarthey Family Foundation" />

    <div className="subpageContainer">
      <LeftMenu active="essay-competition" />
      <div className="body">
        <h2 style={{marginTop: '55px'}}> 2020 Utah Essay Competition Call-For-Entries</h2>
        
        <p>
        $8,000.00 CASH PRIZE ESSAY COMPETITION <br />
        SPONSORED BY THE MCCARTHEY FAMILY FOUNDATION<br />
        FOR STUDENTS GRADES 6 THROUGH COLLEGE & UNIVERSITY IN UTAH  <br />
        Deadline for Entries: Monday September 28, 2020</p>

        <p>In conjunction with the 15th annual McCarthey Family Foundation Lecture Series: In Praise of Independent Journalism, students in grades 6 through college/university (undergraduate and graduate) in Utah are invited to submit an original essay on the following topic: What is the meaning of this quote by Thomas Jefferson for our democracy today: “The only security of all is in a free press.”</p>

        <p><strong>Categories and prizes are as follows:</strong><br />
        University & college students: 1,000-word essay; cash prize: $4,000.00<br />
        Grades 9-12: 750-word essay: cash prize:  $2,500.00<br />
        Grades 6- 8: 500-word essay; cash prize: $1,500.00</p>

        <h2>ENTRY REQUIREMENTS: DEADLINE - Monday 9/28/20. </h2>
        <p>Email a Word document, typed and double-spaced, to <a href="mailto:studentessays@mklpr.com">studentessays@mklpr.com</a>. The student’s name, phone number, email address, school name and grade must be typed on the page with the essay. Essays may be submitted anytime prior to the deadline. Utah students who submitted essays to the 2020 National Student Essay Competition but who did not win are eligible to compete in this statewide competition. </p>

        <p>The winning essayist in each category will be recognized during a video presentation of the 15th Annual McCarthey Family Foundation Lecture: In Praise of Independent Journalism Saturday, November 7, 2020. Guest lecturer Jane Mayer, award-winning investigative journalist and staff writer at The New Yorker, will discuss the presidential election held just a few days earlier. </p>

        <p>For more information: <Link to="/mccarthey-family-foundation-lecture-series">https://mklpr.com/mccarthey-family-foundation-lecture-series</Link> or 801.328.8899.</p>




      </div>
    </div>

    </Layout>
  )
}

export default CallForEntries
