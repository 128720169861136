import React from 'react'
import {Link} from 'gatsby'

const LeftMenu = props => (
  <div className="leftMenu">
    <ul>
      <li className={(props.active === 'home')? 'active' : ''}><Link to="/mccarthey-family-foundation-lecture-series">McCarthey Family Foundation Lecture Series</Link></li>
      <li className={(props.active === 'essay-competition')? 'active' : ''}><Link to="/mccarthey-family-foundation-lecture-series/call-for-entries">Call-For-Entries</Link></li>
      <li className={(props.active === 'winners')? 'active' : ''}><Link to="/mccarthey-family-foundation-lecture-series/winners">2020 Essay Winners</Link></li>
      <li className={(props.active === 'jury')? 'active' : ''}><Link to="/mccarthey-family-foundation-lecture-series/jury">Jury</Link></li>
      {/* <li className={(props.active === 'advisory-committee')? 'active' : ''}><Link to="/mccarthey-family-foundation-lecture-series">Honorary Advisory Committee</Link></li>
      <li className={(props.active === 'guidelines-and-timeline')? 'active' : ''}><Link to="/mccarthey-family-foundation-lecture-series/guidelines-and-timeline">Guidelines &amp; Timeline</Link></li> */}
    </ul>
  </div>
)

export default LeftMenu
